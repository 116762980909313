import { service } from '@/ajax/request'

export const presCategoryTypeList = (params: any) => {
    return service({
        url: `/prescription/presCategory/listByType/${params.categoryType}`,
        method: 'POST',
        params,
        type: "form"
    })
}

export const saveOrUpdatePresCategory = (params: any) => {
    return service({
        url: `/prescription/presCategory/saveOrUpdate`,
        method: 'POST',
        params,
        type: 'string',
    })
}

export const prescriptionSymptom = (params: any) => {
    return service({
        url: `/prescription/prescription/querySymptom/${params.libCode}`,
        method: 'POST',
        params,
        type: 'form',
    })
}

export const prescriptionLoadPres = (symptomId: any, merchantId: string | number) => {
    return service({
        url: `/prescription/prescription/loadPres/${symptomId}${merchantId ? `/${merchantId}` : ""}`,
        method: 'POST',
    })
}

export const prescriptionSaveOrUpdate = (params: any) => {
    return service({
        url: `/prescription/prescription/saveOrUpdate`,
        method: 'POST',
        params,
        type: 'string'
    })
}

export const prescriptionDisable = (id: any) => {
    return service({
        url: `/prescription/prescription/disable/${id}`,
        method: 'POST',
        type: 'string'
    })
}

export const getCategoryList = (parentId: any, merchantId) => {
    return service({
        url: `/prescription/presCategory/loadPresCategory/${parentId}`,
        method: 'POST',
        params: { merchantId },
        type: "form"
    })
}

export const getCategoryDetail = (id) => {
    return service({
        url: `/prescription/presCategory/getById/${id}`,
        method: 'POST',
    })
}

// 品牌方处方库列表
export const getMerchantList = (params) => {
    return service({
        url: `/prescription/merchant/query`,
        method: 'POST',
        params
    })
}
// 品牌方处方 查询门店下拉
export const queryStore = (params) => {
    return service({
        url: `/prescription/merchant/queryStore`,
        method: 'POST',
        params
    })
}
// 品牌方处方 查询门店下拉
export const saveOrUpdateMerchant = (params) => {
    return service({
        url: `/prescription/merchant/saveOrUpdate`,
        method: 'POST',
        params
    })
}
// 品牌方 启用禁用
export const changeMerchantStatus = (params) => {
    return service({
        url: `/prescription/merchant/changeStatus/${params.id}/${params.status}`,
        method: 'POST'
    })
}
// 获取品牌方小程序配置
export const brandMiniConfig = (merchantId) => {
    return service({
        url: `/merchantapp/merchantApp/getMerchantApp?merchantId=${merchantId}`,
        method: 'GET'
    })
}
// 保存品牌方小程序配置
export const saveBrandMiniConfig = (params) => {
    return service({
        url: `/merchantapp/merchantApp/saveOrUpdate`,
        method: 'POST',
        params
    })
}

// 获取saas产品列表
export const saasServiceList = (params) => {
    return service({
        url: `/saasservicemapping/saasServiceProduct/getSaasServiceList`,
        method: 'POST',
        params
    })
}

// 获取saas产品列表
export const saasServiceProduct = (params) => {
    return service({
        url: `/saasservicemapping/saasServiceProduct/add`,
        method: 'POST',
        params,
    })
}

// 获取养身帮Saas服务对应列表
export const saasServiceProductList = (params) => {
    return service({
        url: `/saasservicemapping/saasServiceProduct/list`,
        method: 'POST',
        params,
    })
}

export const saasServiceProductDel = (params) => {
    return service({
        url: `/saasservicemapping/saasServiceProduct/delete`,
        method: 'POST',
        params,
    })
}

export const presCategoryProductQuery = (params) => {
    return service({
        url: `/prescription/presCategory/productQuery`,
        method: "POST",
        params,
    })
}

export const getProductCategoryList = (params) => {
    return service({
        url: `/mall/mall-product-category/list`,
        method: "POST",
        params,
    })
}

export const getProductList = (params) => {
    return service({
        url: `/mall/mall-product/list`,
        method: "POST",
        params,
    })
}

export const saveOrUpdateMallProduct = (params) => {
    return service({
        url: `/prescription/presCategory/saveOrUpdateMallProduct`,
        method: "POST",
        params,
    })
}

export const getProductName = (params) => {
    return service({
        url: `/prescription/presCategory/product`,
        method: "POST",
        params,
        type: "form"
    })
}

export const saasStoreList = (params) => {
    return service({
        url: `/saasservicemapping/saasServiceProduct/get-store-saas-list`,
        method: "POST",
        params
    })
}

export const saasServiceDisable = (params) => {
    return service({
        url: `/saasservicemapping/saasServiceProduct/en-disable`,
        method: "POST",
        params
    })
}